import styled from '@emotion/styled';
import { Flex, Box, Text, Heading } from '@rebass/emotion';
import { jsx, css, useTheme } from '@emotion/react';
import React from 'react';
import ArrowRightIcon from '~icons/nav-icons/icon-line-16-icon-line-arrow-right-16.svg';
import ClockIcon from '~icons/nav-icons/icon-line-16-icon-line-time-16.svg';
import Link from './Link';
import SanityImage from '~components/Elements/SanityImage';
import ReadingTime from '~components/Elements/ReadingTime';
import { usePageContext } from '../../context/PageProvider';

const FlexTypeA = styled(Flex)`
  justify-content: space-between;
  border-bottom: 1px solid ${p => p.theme.colors.neutralNeutral170};
  padding-top: 64px;
  padding-bottom: 64px;
  gap: 32px;
  width: 100%;
  max-width: 1028px;

  @media (max-width: 1024px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
  }
`;

const FlexTypeB = styled(Flex)`
  justify-content: space-between;
  border-bottom: 1px solid ${p => p.theme.colors.neutralNeutral170};
  padding-top: 64px;
  padding-bottom: 64px;
  gap: 32px;
  width: 100%;
  max-width: 1028px;

  @media (max-width: 1024px) {
    flex-direction: column-reverse;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
  }
`;

const FlexTypeC = styled(Flex)`
  width: 100%;
  max-width: 1028px;
  justify-content: space-between;
  border-bottom: 1px solid ${p => p.theme.colors.neutralNeutral170};
  padding-top: 24px;
  align-items: center;
  flex-direction: row;
  padding-bottom: 24px;

  @media (max-width: 767px) {
    flex-direction: column;
    align-items: stretch;
    padding-top: 20px;
    padding-bottom: 20px;
  }
`;

const FlexTypeD = styled(Flex)`
  width: 100%;
  max-width: 1028px;
  background-color: ${p => p.theme.colors.blueBlue20};
  justify-content: space-between;
  flex-direction: column;
  padding: 32px;
  margin: 32px 0;
  border-radius: 8px;
`;

const Image = ({ src, style, transform, ...props }) => {
  return (
    <Flex {...props}>
      <SanityImage src={src} style={style} transform={transform} />
    </Flex>
  );
};

const DurationRow = ({ page, pageType, cardType, small = false }) => {
  return !page.readingTime || (cardType && cardType === 4) ? (
    <Flex
      flexDirection="row"
      justifyContent="flex-start"
      alignItems="center"
      mr={'5px'}
      mt={'5px'}
    >
      <Text
        fontSize="16px"
        color={'neutralNeutral170'}
        style={{ textDecoration: 'underline' }}
        mr="10px"
      >
        <Link data={{ page, pathway: pageType === 'pathway' }}>
          {small ? 'READ' : 'READ MORE'}
        </Link>
      </Text>
      <ArrowRightIcon />
    </Flex>
  ) : (
    <Flex
      flexDirection="row"
      justifyContent="flex-start"
      alignItems="center"
      m={'5px'}
    >
      <ClockIcon />
      <ReadingTime readingTime={page.readingTime} ml="10px" />
      <span style={{ width: '22px' }}></span>
      <Text fontSize="16px" color={'neutralNeutral170'} m={'5px'} mr="10px">
        <Link data={{ page, pathway: pageType === 'pathway' }}>
          {small ? 'READ' : 'READ MORE'}
        </Link>
      </Text>
      <ArrowRightIcon />
    </Flex>
  );
};

const Card = ({ data, type }) => {
  const ctx = usePageContext();
  const pageType = ctx?.page?.menu?.type || 'explore';

  return type === 1 ? (
    <FlexTypeA>
      {data.page.image ? (
        <Link data={data}>
          <Image
            maxWidth={['100%', '100%', '100%', '100%', '604px']}
            px={[0, 0, 0, '50px', 0]}
            style={{ borderRadius: '10px' }}
            src={data.page.image}
            transform={transformer =>
              transformer
                .height(376)
                .width(604)
                .fit('crop')
                .auto('format')
                .dpr(window?.devicePixelRatio || 1)
            }
          ></Image>
        </Link>
      ) : null}
      <Flex
        flexDirection="column"
        justifyContent="center"
        gap="24px"
        width={['100%', '100%', '100%', '80%', 'calc(1028px - 288px - 32px)']}
      >
        <Link data={data} style={{ textDecoration: 'none' }}>
          <Heading
            as="h2"
            fontSize="28px"
            color={'blueBlue160'}
            mt={['10px', '10px', '10px', 0]}
          >
            {data.page.title}
          </Heading>
        </Link>
        <Text
          lineHeight="1.5"
          fontSize="16px"
          mt="23px"
          mb="29px"
          color={'neutralNeutral180'}
        >
          {data.page.description}
        </Text>
        <DurationRow page={data.page} duration={10} />
      </Flex>
    </FlexTypeA>
  ) : type === 2 ? (
    <FlexTypeB>
      <Flex
        flexDirection="column"
        justifyContent="center"
        gap="24px"
        width={['100%', '100%', '100%', '80%', 'calc(1028px - 288px - 32px)']}
      >
        <Link data={data} style={{ textDecoration: 'none' }}>
          <Heading
            as="h2"
            fontSize="28px"
            color={'blueBlue160'}
            mt={['10px', '10px', '10px', 0]}
          >
            {data.page.title}
          </Heading>
        </Link>
        <Text
          lineHeight="1.5"
          fontSize="16px"
          mt="23px"
          mb="29px"
          color={'neutralNeutral180'}
        >
          {data.page.description}
        </Text>
        <DurationRow page={data.page} duration={10} />
      </Flex>
      {data.page.image ? (
        <Link data={data}>
          <Image
            style={{
              borderRadius: '8px',
            }}
            maxWidth={['100%', '100%', '100%', '100%', '288px']}
            src={data.page.image}
            transform={transformer =>
              transformer
                .height(212)
                .width(288)
                .fit('crop')
                .auto('format')
                .dpr(window?.devicePixelRatio || 1)
            }
          ></Image>
        </Link>
      ) : null}
    </FlexTypeB>
  ) : type === 3 ? (
    <FlexTypeC>
      <Link data={data} style={{ textDecoration: 'none' }}>
        <Heading as="h2" fontSize="20px" color={'neutralNeutral180'}>
          {data.page.title}
        </Heading>
      </Link>
      <DurationRow page={data.page} duration={10} />
    </FlexTypeC>
  ) : type === 4 ? (
    <FlexTypeD>
      <Link
        data={{ ...data, pathway: pageType === 'pathway' }}
        style={{ textDecoration: 'none' }}
      >
        <Heading as="h2" fontSize="20px" color={'neutralNeutral180'}>
          {data.page.title}
        </Heading>
      </Link>

      <Text
        lineHeight="1.5"
        fontSize="16px"
        mt="23px"
        mb="29px"
        color={'neutralNeutral180'}
      >
        {data.page.description}
      </Text>
      <DurationRow
        page={data.page}
        pageType={pageType}
        duration={10}
        cardType={4}
      />
    </FlexTypeD>
  ) : (
    <FlexTypeB>
      <Flex
        flexDirection="column"
        justifyContent="center"
        gap="24px"
        width={[
          '100%',
          '100%',
          '100%',
          'calc(100% - 40px)',
          'calc(1028px - 288px - 32px)',
        ]}
      >
        <Link data={data} style={{ textDecoration: 'none' }}>
          <Heading
            as="h2"
            fontSize="20px"
            color={'neutralNeutral180'}
            mt={['10px', '10px', '10px', 0]}
          >
            {data.page.title}
          </Heading>
        </Link>
        <Text
          lineHeight="1.5"
          fontSize="16px"
          mt="23px"
          mb="29px"
          color={'neutralNeutral180'}
        >
          {data.page.description}
        </Text>
        <DurationRow page={data.page} small={true} />
      </Flex>
      {data.page.image ? (
        <Link data={data}>
          <Image
            style={{
              borderRadius: '8px',
            }}
            width={['100%', '100%', '100%', '100%', '180px']}
            maxHeight={['212px', '212px', '212px', '212px', '136px']}
            src={data.page.image}
            transform={transformer =>
              transformer
                .maxHeight(212)
                .fit('crop')
                .auto('format')
                .dpr(window?.devicePixelRatio || 1)
            }
          ></Image>
        </Link>
      ) : null}
    </FlexTypeB>
  );
};

export default Card;
