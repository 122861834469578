import React from 'react';
import { Flex } from '@rebass/emotion';
import SanityRichTextContent from '~components/Elements/SanityRichTextContent';
import styled from '@emotion/styled';

const Columns = ({ data }) => {
  const Container = styled(Flex)`
    .media-card__imageContainer {
      width: 80px !important;
      min-width: 80px !important;
      max-width: 80px !important;

      div {
        max-width: 100% !important;
        width: 100% !important;
        max-height: 150px !important;
      }
      img {
        width: 80px !important;
        max-width: 80px !important;
        height: auto !important;
        margin: 0 auto !important;
      }
    }
  `;

  return (
    <Container
      flexDirection={['column', 'column', 'column', 'column', 'column', 'row']}
      justifyContent="space-between"
      alignItems="flex-start"
      mt="10px"
    >
      {data.items.map((column, idx) => (
        <Flex
          key={idx}
          flexDirection="column"
          width="100%"
          justifyContent="flex-start"
          alignItems="center"
        >
          <SanityRichTextContent content={column.content} width="100%" />
        </Flex>
      ))}
    </Container>
  );
};

export default Columns;
