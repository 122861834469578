import React from 'react';
import { Box, Flex } from 'rebass';
import theme from '~lib/theme';
import { usePageContext } from '../../context/PageProvider';

export const defaultBlockSpacing = {
  pr: {
    xs: 20,
    tabletP: 25,
    tabletL: 25,
    lg: 70,
    xl: 120,
  },
  pl: {
    xs: 20,
    tabletP: 25,
    tabletL: 25,
    lg: 70,
    xl: 120,
  },
};

const Block = props => {
  const {
    children,
    innerProps,
    type = 'light',
    redefinePl,
    redefinePr,
    redefineMb,
    ...styleProps
  } = props;

  const { isHomePage } = usePageContext();

  const widths = {
    ...theme.containerWidths,
  };
  const bg = {
    light: '#fff',
    dark: theme.colors.neutralNeutral0,
  }[type];
  if (props.snug) {
    widths.xl = widths.lg;
  }

  return (
    <Flex
      pl={{
        ...defaultBlockSpacing.pl,
        ...redefinePl,
      }}
      pr={{
        ...defaultBlockSpacing.pr,
        ...redefinePr,
      }}
      alignItems="center"
      justifyContent="center"
      bg={bg}
      {...styleProps}
    >
      <Box width={widths} {...innerProps}>
        {children}
      </Box>
    </Flex>
  );
};

export default Block;
