import React from 'react';
import { Heading as RebassHeading, Text, Flex } from 'rebass';
import { useThemeUI } from 'theme-ui';

const Heading = ({ data: { level, text, color, mb, pt, align }, ...props }) => {
  const fontSizes = {
    h0: 64,
    h1: 44,
    h2: {
      xs: 20,
      tabletL: 28,
    },
    h3: 20,
  };

  const lineHeights = {
    h0: '1.5',
    h1: '52px',
    h2: '36px',
    h3: '28px',
  };

  const { theme } = useThemeUI();

  return level === 'h0' || level === 'h1' ? (
    <Flex
      width="100%"
      maxWidth={['100%', '100%', '824px', '1440px']}
      alignSelf="center"
      mt="40px"
      className="heading-container"
    >
      <RebassHeading
        textAlign={align || 'left'}
        as={level === 'h0' ? 'h1' : level}
        lineHeight={lineHeights[level]}
        fontSize={fontSizes[level]}
        fontWeight="bold"
        mb={
          mb || {
            xs: 30,
            md: 40,
          }
        }
        pt={pt || 0}
        {...props}
        color={color || level === 'h3' ? '#111' : '#1a3092'}
        style={{ fontFamily: 'Work Sans' }}
      >
        {text}
      </RebassHeading>
    </Flex>
  ) : (
    <Flex
      width="100%"
      maxWidth={['100%', '100%', '824px', '1440px']}
      alignSelf="center"
      mt="20px"
      className="heading-container"
    >
      <Text
        textAlign={align || 'center'}
        lineHeight={lineHeights[level]}
        fontSize={fontSizes[level]}
        fontWeight="bold"
        mb={
          mb || {
            xs: 30,
            md: 50,
          }
        }
        pt={pt || 0}
        {...props}
        color={color || level === 'h3' ? '#111' : '#1a3092'}
        style={{ fontFamily: 'Work Sans' }}
      >
        {text}
      </Text>
    </Flex>
  );
};

export default Heading;
