import React from 'react';
import { Flex } from '@rebass/emotion';
import css from '@emotion/css';
import Text from '~components/Elements/Text';
import Icon from '~components/Elements/Icon';

const TakeawayItem = ({ item }) => {
  return (
    <Flex justifyContent="flex-start" alignItems="flex-start">
      <Icon
        name="component-bullet-arrow-md"
        color="#fff"
        width="16px"
        height="24px"
      />
      <Text pl={2} font={'Body'} color={'neutralNeutral0'}>
        {item}
      </Text>
    </Flex>
  );
};

const KeyTakeaways = ({ data }) => {
  return (
    <Flex
      mt="20px"
      mb="50px"
      flexDirection="column"
      justifyContent="flex-start"
      alignItems="flex-start"
      pl="31px"
      py="32px"
      pr="41px"
      bg="blueBlue120"
      css={css`
        border-radius: 8px;
        gap: 24px;
      `}
    >
      <Text font={'HeadingS'} color={'orangeOrange40'}>
        Key Takeaways
      </Text>
      {data.takeaways.map(item => (
        <TakeawayItem key={item} item={item} />
      ))}
    </Flex>
  );
};

export default KeyTakeaways;
