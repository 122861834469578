import React from 'react';
import { Flex, Box } from '@rebass/emotion';
import styled from '@emotion/styled';
import SanityRichTextContent from '~components/Elements/SanityRichTextContent';
import Image from './Image';

const Container = styled(Flex)`
  .text-block {
    margin-top: 0 !important;
  }
`;

const ImageContainer = styled(Box)`
  width: 224px;
  min-width: 224px;
  max-width: 224px;

  div {
    max-width: 100% !important;
    width: 100% !important;
    max-height: 350px !important;
    padding: 0 !important;
  }
  img {
    width: 224px !important;
    max-width: 224px !important;
    height: auto !important;
    margin: 0 auto !important;
  }

  @media (max-width: 768px) {
    width: 80px !important;
    min-width: 80px !important;
    max-width: 80px !important;

    div {
      max-width: 100% !important;
      width: 100% !important;
      max-height: 150px !important;
    }
    img {
      width: 80px !important;
      max-width: 80px !important;
      height: auto !important;
      margin: 0 auto !important;
    }
  }
`;

const MediaCard = ({ data }) => {
  return (
    <Container
      flexDirection="row"
      justifyContent="flex-start"
      alignItems="flex-start"
      className="media-card"
      mb="20px"
    >
      <ImageContainer className="media-card__imageContainer">
        <Image data={{ ...data.image, size: 'large' }} />
      </ImageContainer>
      <Box className="media-card__contentContainer" ml="20px">
        <SanityRichTextContent content={data.content} />
      </Box>
    </Container>
  );
};

export default MediaCard;
