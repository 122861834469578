import React from 'react';
import { Flex } from '@rebass/emotion';

import AutismConnectXL from '../../images/autism-connect-xl.svg';
import AutismConnectLG from '../../images/autism-connect-lg.svg';
import AutismConnectSM from '../../images/autism-connect-sm.svg';

import aaaXL from '../../images/aaa-xl.svg';
import aaaLG from '../../images/aaa-lg.svg';
import aaaSM from '../../images/aaa-sm.svg';

const Banner = ({ type = 'AutismConnect' }) => {
  return (
    <Flex justifyContent="center" alignItems="center" mt="20px" mb="20px">
      {type === 'AutismConnect' ? (
        <a href="https://www.amaze.org.au/autismconnect/" target="_blank">
          <img src={AutismConnectXL} className="only-lg-xl" />
          <img src={AutismConnectLG} className="only-md" />
          <img src={AutismConnectSM} className="only-sm" />
        </a>
      ) : (
        <a href="https://www.autismawareness.com.au/" target="_blank">
          <img src={aaaXL} width="824" className="only-lg-xl" />
          <img src={aaaLG} width="600" className="only-md" />
          <img src={aaaSM} width="400" className="only-sm" />
        </a>
      )}
    </Flex>
  );
};

export default Banner;
