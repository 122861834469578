export default function WatchIcon({
  width = 24,
  height = 24,
  color = 'black',
  stroke = 2,
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.25 6.75H3.75C3.33579 6.75 3 7.08579 3 7.5V18.75C3 19.1642 3.33579 19.5 3.75 19.5H20.25C20.6642 19.5 21 19.1642 21 18.75V7.5C21 7.08579 20.6642 6.75 20.25 6.75Z"
        stroke={color}
        stroke-width={stroke}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.5 2.25L12 6.75L16.5 2.25"
        stroke={color}
        stroke-width={stroke}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
