import React from 'react';
import styled from '@emotion/styled';
import { Flex } from 'rebass';
import { StandardText } from '../constants/typography';
import Block from '../Elements/Block';

const StyledQuote = styled(StandardText)`
  display: block;
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 28px;
  letter-spacing: normal;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  text-transform: uppercase;

  em,
  strong {
    display: block;
    font-size: 20px;
    font-style: normal;
    font-weight: normal;
    color: #0052cc;
    text-transform: none;
    margin-bottom: 10px;

    &:only-child {
      margin-bottom: 0;
    }
  }
`;

const QuoteMark = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 54px;
  margin-right: 20px;
  color: #0052cc;
  font-size: 128px;
  height: 50px;
  padding-top: 50px;
`;

export const PullQuoteBase = ({ children, ...props }) => (
  <Flex justifyContent="flex-start" alignItems="flex-start" px="20px" py="30px">
    <QuoteMark>&ldquo;</QuoteMark>
    <StyledQuote as="q" {...props}>
      {children}
    </StyledQuote>
  </Flex>
);

const PullQuote = ({ data: { text } }) => {
  return (
    <Block
      redefineMb={{
        xs: 50,
        tabletP: 60,
        lg: 60,
      }}
    >
      <PullQuoteBase>{text}</PullQuoteBase>
    </Block>
  );
};

export default PullQuote;
