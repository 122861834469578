import styled from '@emotion/styled';
import { Flex } from '@rebass/emotion';
import React, { useMemo, useState } from 'react';
import Text from '~components/Elements/Text';
import SanityRichTextContent from '~components/Elements/SanityRichTextContent';

function Tab({ tab }) {
  return (
    <div className={`tabs__content`}>
      <SanityRichTextContent content={tab.content} />
    </div>
  );
}

function Navigation(props) {
  return (
    <ul className={`tabs__nav`}>
      {props.tabs.map(item => (
        <li key={item.title} className={`tabs__item`}>
          <button
            className={`tabs__button ${
              props.activeTabId === item.title ? 'active' : ''
            }`}
            onClick={() => props.onNavClick(item.title)}
          >
            {item.title}
          </button>
        </li>
      ))}
    </ul>
  );
}

function Tabs({ data }) {
  const TabsFlex = styled(Flex)`
    .tabs__content {
      padding-top: 32px;
      font-family: ${p => p.theme.fonts.Body};
    }

    .tabs__nav {
      display: flex;
      align-items: center;
      list-style: none;
      border-bottom: 1px solid #091e42;
      padding: 20px 0 12px;

      @media (max-width: ${props => props.theme.device.lg}) {
        flex-wrap: wrap;
        justify-content: flex-start;
        padding-bottom: 0;
      }
    }

    .tabs__button {
      background: rgba(0, 0, 0, 0);
      padding: 10px;
      color: #091e42;
      box-shadow: none;
      outline: none;
      border: none;
      cursor: pointer;
      display: block;
      position: relative;
      font-family: ${p => p.theme.fonts.BodyBulletpoint};

      @media (max-width: ${props => props.theme.device.lg}) {
        padding: 5px 10px;
        margin-bottom: 20px;
        text-align: center;
      }
    }

    .tabs__button:after {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      bottom: -12px;
      width: 100%;
      height: 4px;
      background: ${props => props.theme.colors.orangeOrange160};
      opacity: 0;
      visibility: hidden;
      transition: visibility 0.5s, opacity 0.4s ease-in-out;
    }

    .tabs__button.active::after {
      opacity: 1;
      visibility: visible;
      transition: visibility 0.5s, opacity 0.4s ease-in-out;
    }

    .tabs__item:not(:last-child) {
      padding-right: 40px;

      @media (max-width: ${props => props.theme.device.md}) {
        padding-right: 0;
      }
    }
  `;

  const [activeTabId, setActiveTab] = useState(
    data.tabs.length ? data.tabs[0].title : null
  );

  const activeTab = useMemo(
    () => data.tabs.find(tab => tab.title === activeTabId),
    [activeTabId, data.tabs]
  );

  return (
    <TabsFlex className={`tabs`} flexDirection="column">
      {data.title ? (
        <Text font={'HeadingS'} color={'neutralNeutral180'}>
          {data.title}
        </Text>
      ) : null}
      <Navigation
        tabs={data.tabs}
        onNavClick={setActiveTab}
        activeTabId={activeTabId}
      />
      <Tab tab={activeTab} />
    </TabsFlex>
  );
}

export default Tabs;
