import React, { createContext, useContext, useMemo, useState } from 'react';

// basic create context
const MediaResourceContext = createContext();

// create the provider to insert into the app
const MediaResourceProvider = ({ children }) => {
  let [activeFilter, setActiveFilter] = useState(undefined);
  let [mediaResourcesData, setMediaResourcesData] = useState(undefined);
  let [mediaResourcesItems, setMediaResourcesItems] = useState(undefined);

  // memoize the context values to avoid re-rendering
  const value = useMemo(
    () => ({
      activeFilter,
      setActiveFilter,
      mediaResourcesData,
      setMediaResourcesData,
      mediaResourcesItems,
      setMediaResourcesItems,
    }),
    [
      mediaResourcesData,
      setMediaResourcesData,
      mediaResourcesItems,
      setMediaResourcesItems,
    ]
  );
  return (
    <MediaResourceContext.Provider value={value}>
      {children}
    </MediaResourceContext.Provider>
  );
};

// create hook to easily reuse the context
export const useMediaResourceContext = () => useContext(MediaResourceContext);

export { MediaResourceContext, MediaResourceProvider };
