export default function ListenIcon({
  width = 24,
  height = 24,
  color = 'black',
  stroke = 2,
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.1406 12.75H18.1406C17.7428 12.75 17.3613 12.908 17.08 13.1893C16.7987 13.4707 16.6406 13.8522 16.6406 14.25V18C16.6406 18.3978 16.7987 18.7794 17.08 19.0607C17.3613 19.342 17.7428 19.5 18.1406 19.5H19.6406C20.0384 19.5 20.42 19.342 20.7013 19.0607C20.9826 18.7794 21.1406 18.3978 21.1406 18V12.75ZM21.1406 12.75C21.1407 11.5618 20.9054 10.3853 20.4484 9.28844C19.9915 8.1916 19.3218 7.1961 18.4781 6.35938C17.6344 5.52267 16.6334 4.8613 15.5328 4.41344C14.4322 3.96559 13.2538 3.74011 12.0656 3.75001C10.8782 3.74135 9.70083 3.96775 8.60132 4.41616C7.5018 4.86458 6.50189 5.52614 5.6592 6.36273C4.81651 7.19932 4.1477 8.1944 3.69131 9.29063C3.23492 10.3869 2.99997 11.5626 3 12.75V18C3 18.3978 3.15804 18.7794 3.43934 19.0607C3.72064 19.342 4.10218 19.5 4.5 19.5H6C6.39782 19.5 6.77936 19.342 7.06066 19.0607C7.34196 18.7794 7.5 18.3978 7.5 18V14.25C7.5 13.8522 7.34196 13.4707 7.06066 13.1893C6.77936 12.908 6.39782 12.75 6 12.75H3"
        stroke={color}
        stroke-width={stroke}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
