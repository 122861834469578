import React from 'react';
import { Flex } from '@rebass/emotion';
import css from '@emotion/css';
import SanityRichTextContent from '~components/Elements/SanityRichTextContent';

const HighlightBox = ({ data }) => {
  return (
    <Flex
      mt="20px"
      mb="50px"
      flexDirection="column"
      justifyContent="flex-start"
      alignItems="flex-start"
      pl="31px"
      py="32px"
      pr="41px"
      bg="blueBlue120"
      css={css`
        border-radius: 8px;
        gap: 24px;

        .heading-container {
          margin-top: 0 !important;
        }
      `}
    >
      {data.content ? (
        <SanityRichTextContent content={data.content} forceColor="#fff" />
      ) : null}
    </Flex>
  );
};

export default HighlightBox;
