import React from 'react';
import { Flex, Box } from 'rebass';
import SanityImage from '../Elements/SanityImage';
import styled from '@emotion/styled';

const sizes = {
  extrasmall: '25%',
  small: '50%',
  medium: '75%',
  large: '100%',
};

const StyledImage = styled(SanityImage)`
  @media (min-width: 1024px) {
    ${props =>
      props.inPage
        ? `
      &&& {
        object-fit: cover;
        max-width: ${sizes[props.imageSize]} !important;
        height: auto;
        margin: 0 auto;
      }
    `
        : `
      &&& {
        object-fit: cover;
        max-width: ${sizes[props.imageSize]} !important;
        height: auto;
      }
    `}
  }
`;

export const ImageBase = ({ data = {}, transform, inPage, ...props }) => {
  const { caption, size = 'medium', title, alt } = data;

  return (
    <StyledImage
      imageSize={size}
      inPage={inPage}
      alt={alt}
      caption={caption}
      title={title}
      src={data}
      transform={transform}
      {...props}
    />
  );
};

const Image = ({ data, ...props }) => {
  return (
    <Flex
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      width="100%"
      maxWidth={['100%', '100%', '100%', '824px', '1440px']}
      px={['20px', '20px', '20px', 0, 0]}
      mx="auto"
      {...props}
    >
      <Box width="100%" maxWidth={['100%', '100%', '824px', '1440px']}>
        <ImageBase inPage data={data} />
      </Box>
    </Flex>
  );
};

export default Image;
