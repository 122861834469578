import React from 'react';
import SanityRichTextContent from '~components/Elements/SanityRichTextContent';
import { Text } from 'rebass';

const ReadingTime = ({ content, readingTime, ...props }) => {
  if (readingTime) {
    return (
      <Text fontSize="16px" color={'neutralNeutral170'} m={'5px'} {...props}>
        {readingTime} MIN READ
      </Text>
    );
  }

  const AVERAGE_WORDS_PER_MINUTE = 225;

  return (
    <SanityRichTextContent
      renderRawText={rawText => (
        <Text fontSize="16px" color={'neutralNeutral170'} m={'5px'} {...props}>
          {Math.ceil(rawText.split(/ +/g).length / AVERAGE_WORDS_PER_MINUTE) ||
            1}{' '}
          MIN READ
        </Text>
      )}
      content={content}
    />
  );
};

export default ReadingTime;
