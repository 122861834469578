import React from 'react';

import SanityRichTextContent from '../Elements/SanityRichTextContent';

const RichTextContent = ({
  data: { blockContent },
  width,
  px,
  next = {},
  ...props
}) => {
  return (
    <SanityRichTextContent width="100%" content={blockContent} {...props} />
  );
};

export default RichTextContent;
