import React from 'react';

import getVideoId from 'get-video-id';
import Block from '../Elements/Block';
import styled from '@emotion/styled';
import { layout, space } from 'styled-system';

const StyledIframe = styled.iframe`
  width: 100%;
  height: auto;
  background-color: #fff;
  ${layout};
  ${space};
`;

const IndexIframe = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
`;

export const VideoBase = React.memo(({ url, className, isIndex }) => {
  if (url) {
    const videoData = getVideoId(url);
    const id = videoData.id;
    const service = videoData.service;
    const vimeoEmbedUrl = 'https://player.vimeo.com/video/' + id;
    const youtubeEmbedUrl = 'https://www.youtube.com/embed/' + id;
    if (!id) {
      return <div>Missing YouTube URL</div>;
    }
    if (service === 'vimeo' && isIndex) {
      return (
        <IndexIframe
          className={className}
          src={vimeoEmbedUrl}
          frameBorder="0"
          allow="autoplay; fullscreen"
          allowFullScreen={true}
        ></IndexIframe>
      );
    }
    if (service === 'vimeo') {
      return (
        <StyledIframe
          className={className}
          src={vimeoEmbedUrl}
          frameBorder="0"
          allow="autoplay; fullscreen"
          allowFullScreen={true}
        ></StyledIframe>
      );
    }
    if (service === 'youtube') {
      return (
        <StyledIframe
          className={className}
          src={youtubeEmbedUrl}
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen={true}
        ></StyledIframe>
      );
    }
  }
  return null;
});

export default ({ data: { url } }) => {
  return (
    <Block>
      <VideoBase url={url} />
    </Block>
  );
};
