import React from 'react';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';
import { Heading, Text as RebassText } from 'rebass';
import css from '@emotion/css';

const SMALL_FONT_SIZE = '13px';

// it is the same as H3 but without text being bold
let InfoTextAsH2 = styled.div`
  color: ${props => props.color || props.theme.colors.neutralNeutral180};
  font-size: 21px;
  line-height: 26px;
  margin-bottom: ${props => props.marginBottom}px;
`;

export { InfoTextAsH2 };

const StyledSmall = styled.div`
  font-size: ${SMALL_FONT_SIZE};
  color: ${props => props.color || '#000'};
`;

export const SmallStyle = props => css`
  font-size: ${SMALL_FONT_SIZE};
  color: ${props.color};
`;

export const H1 = ({ type, children, ...props }) => {
  return (
    <Heading as={type ? type : 'h1'} fontSize={{ xs: 4, sm: 5 }} {...props}>
      {children}
    </Heading>
  );
};

export const H2 = ({ type, children, ...props }) => {
  return (
    <Heading as={type ? type : 'h2'} fontSize={3} {...props}>
      {children}
    </Heading>
  );
};

export const H3 = ({ theme, type, children, color, className, ...props }) => {
  return (
    <Heading
      as={type ? type : 'h3'}
      className={className}
      color={color ? color : theme.colors.neutralNeutral180}
      {...props}
    >
      {children}
    </Heading>
  );
};

export const H4 = ({ type, children, ...props }) => {
  return (
    <Heading as={type ? type : 'h4'} fontSize={2} {...props}>
      {children}
    </Heading>
  );
};

export const Small = ({ theme, type, color, children }) => {
  return (
    <StyledSmall
      color={color ? color : theme.colors.neutralNeutral180}
      as={type ? type : 'small'}
    >
      {children}
    </StyledSmall>
  );
};

export const P = ({ type, children, ...props }) => {
  return (
    <RebassText
      as={type ? type : 'p'}
      fontSize={2}
      lineHeight="24px"
      {...props}
    >
      {children}
    </RebassText>
  );
};

const textStyles = props => css`
  word-break: break-word;
  line-height: ${props.lineHeight || 21}px;
  color: ${props.color
    ? props.theme.colors[props.color] || props.color
    : props.theme.colors.neutralNeutral180};
  font-size: ${props.fontSize
    ? props.theme.fontSizes[props.fontSize] || `${props.fontSize}px`
    : props.theme.fontSizes[2]}px;
  font-weight: ${props.bold ? 'bold' : 'normal'};
  font-family: ${props.fontFamily
    ? `"${props.fontFamily}" !important`
    : '"FS Me" !important'};
`;

export const StandardHeading = styled(Heading)`
  ${props =>
    textStyles({
      ...props,
      fontFamily: 'FS Me Ultra',
      fontSize: props.fontSize || 32,
    })};
`;

// TODO: reuse these for standard text as well
const StandardTextBase = styled(RebassText)`
  word-break: break-word;
  cursor: ${props => (props.clickable ? 'pointer' : 'inherit')};
  line-height: ${props => props.lineHeight || '21px'};
  color: ${props =>
    props.color
      ? props.theme.colors[props.color] || props.color
      : props.theme.colors.neutralNeutral180};
  font-size: ${props =>
    props.fontSize
      ? props.theme.fontSizes[props.fontSize] || `${props.fontSize}px`
      : props.theme.fontSizes[2]}px;
  font-weight: ${props => (props.bold ? 'bold' : '')};
  text-align: ${props => props.textAlign || 'left'};
  display: ${props => (props.inline ? 'inline' : 'block')};

  em {
    font-style: italic;
  }
`;

const TextResponsiveBase = styled(StandardTextBase)`
  text-align: center;
  @media (max-width: ${props => props.theme.device.md}) {
    text-align: left;
  }
`;

export const TextResponsive = TextResponsiveBase;

export const StandardText = props => {
  const { theme } = useTheme();
  return <StandardTextBase theme={theme} {...props} />;
};
