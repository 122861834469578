import React, { useMemo } from 'react';
import sanityImgBuilder from '~lib/sanity/sanityImgBuilder';
import styled from '@emotion/styled';
import { path } from 'lodash/fp';
import { rewriteSanityUrl } from '~lib/sanity/sanityUtils';
import { Image } from 'rebass';

const Caption = styled.figcaption`
  font-size: 14px;
  line-height: 1.5;
  color: rgba(15, 15, 15, 0.87);
  margin-top: 22px;
`;

const StyledFigure = styled.figure`
  transition: all 1s ease-in-out;
  display: flex;
  flex-direction: column;
`;

const StyledImage = styled(Image)`
  max-width: 100%;
  min-width: auto !important;
  height: ${props =>
    props.hotspot && props.dimensions
      ? `${props.hotspot.height * props.dimensions.height}px`
      : props.fullHeight
      ? '100%'
      : 'auto'};
  max-height: ${props => props.maxHeight || 'auto'};
  object-fit: ${props => (props.hotspot ? 'cover' : 'cover')};
  width: ${props => (props.fullWidth ? '100%' : 'auto')};
  object-position: ${props =>
    props.hotspot
      ? `${props.hotspot.x * 100}% ${props.hotspot.y * 100}%`
      : undefined};
`;

const getHotspot = ({ useHotspot, builder = {}, hotspot }) => {
  // if width and/or height is specified then the cropping/hot-spotting is done on the sanity side
  // and additional FE management is not needed
  if (path('options.height')(builder) || path('options.width')(builder)) {
    return false;
  }

  return useHotspot && hotspot;
};

const SanityImage = ({
  src = {},
  alt,
  caption,
  transform,
  fullWidth,
  useHotspot = true,
  ...props
}) => {
  if (!src?.asset) {
    return null;
  }

  const { imageSrc, builder } = useMemo(() => {
    let builder = sanityImgBuilder.image(src);
    if (transform) {
      builder = transform(builder);
    }

    return {
      imageSrc: builder.url(),
      builder,
    };
  }, [src, transform]);

  const hotspot = getHotspot({
    useHotspot,
    hotspot: src.hotspot,
    builder,
  });

  const dimensions = path('asset.metadata.dimensions')(src);

  if (!caption) {
    return (
      <StyledImage
        dimensions={dimensions}
        fullWidth={fullWidth}
        hotspot={hotspot}
        src={imageSrc}
        alt={alt}
        {...props}
      />
    );
  }

  return (
    <StyledFigure {...props}>
      <StyledImage
        dimensions={dimensions}
        fullWidth={fullWidth}
        hotspot={hotspot}
        alt={alt}
        src={imageSrc}
      />
      {caption && <Caption>{caption}</Caption>}
    </StyledFigure>
  );
};

export default SanityImage;
