import { useEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import { Flex } from 'rebass';
import SanityImage from '../Elements/SanityImage';
import SanityRichTextContent from '~components/Elements/SanityRichTextContent';
import WatchIcon from '~icons/watch';
import ReadIcon from '~icons/read';
import ListenIcon from '~icons/listen';
import Link from './Link';
import { useMediaResourceContext } from '../../context/MediaResourceProvider';
import { AnimatePresence, motion, LayoutGroup } from 'framer-motion';

export default function MediaResources({ data }) {
  const mediaResources = useStaticQuery(query);

  // grab the values from the media resource context
  let {
    mediaResourcesData,
    setMediaResourcesData,
    mediaResourcesItems,
    setMediaResourcesItems,
  } = useMediaResourceContext();

  // filter for adult or child content
  const adult = data.filterContent == 'adult';
  const children = data.filterContent == 'children';

  // if the component lists all, grab the data from the static query
  // if the component lists chooseItems, grab the data from the component props
  useEffect(() => {
    // filter the data based on the category filtering options
    let filteredByDisplay;
    if (data?.displayContent === 'all') {
      filteredByDisplay = mediaResources?.allSanityMediaResource?.nodes;
    } else {
      filteredByDisplay = data?.items;
    }

    if (adult) {
      setMediaResourcesData(
        filteredByDisplay?.filter(item => item.category === 'adult')
      );
    } else if (children) {
      setMediaResourcesData(
        filteredByDisplay?.filter(item => item.category === 'children')
      );
    }
  }, [mediaResources]);

  useEffect(() => {
    setMediaResourcesItems(mediaResourcesData);
  }, [mediaResourcesData]);

  return (
    <div
      id="media-resources"
      className="grid scroll-mt-16 gap-8 md:grid-cols-2"
    >
      <AnimatePresence initial={false}>
        {mediaResourcesItems?.map(item => {
          return (
            <MediaResourceCard
              key={item?._rev}
              link={item.link}
              imageSrc={item.image}
              title={item.title}
              category={item.category}
              type={item.type}
              description={item.description?._rawBlockContent}
            />
          );
        })}
      </AnimatePresence>
    </div>
  );
}

function MediaResourceCard({ imageSrc, title, type, description, link }) {
  const Image = ({ src, style, transform, ...props }) => {
    return (
      <Flex {...props}>
        <SanityImage src={src} style={style} transform={transform} />
      </Flex>
    );
  };

  function iconSwitch() {
    switch (type) {
      case 'watch':
        return <WatchIcon />;
      case 'read':
        return <ReadIcon />;
      case 'listen':
        return <ListenIcon />;
    }
  }

  return (
    <motion.div
      layout
      animate={{
        opacity: 1,
        transition: { duration: 0.1, ease: [0.17, 0.67, 0.83, 0.67] },
      }}
      initial={{ opacity: 0 }}
      exit={{
        opacity: 0,
        transition: { duration: 0.1, ease: [0.17, 0.67, 0.83, 0.67] },
      }}
      className="w-full min-h-[500px]"
    >
      <div className="font-bold pb-2 flex flex-row space-x-2">
        {iconSwitch()}{' '}
        <span>{type.charAt(0).toUpperCase() + type.slice(1)}</span>
      </div>
      <Link
        noStyles
        data={{
          url: link.url,
          page: link.page,
          file: link.file,
          newTab: link.newTab,
        }}
      >
        <div className="space-y-3">
          <Image
            src={imageSrc}
            transform={transformer =>
              transformer
                .height(500)
                .width(800)
                .fit('crop')
                .auto('format')
                .dpr(window?.devicePixelRatio || 1)
            }
          />
          <h3 className="text-xl text-dark-blue leading-tight p-0">{title}</h3>
          <SanityRichTextContent content={description} />
        </div>
      </Link>
    </motion.div>
  );
}

const query = graphql`
  query mediaResoucesQuery {
    allSanityMediaResource {
      totalCount
      nodes {
        _createdAt
        _updatedAt
        _rev
        _key
        title
        category
        type
        id
        link {
          _key
          _type
          url
          newTab
          _rawPage
          _rawFile
          file {
            _id
            _type
            _createdAt
            _updatedAt
            _rev
            _key
            title
            _rawFile
            id
          }
          page {
            ... on SanityContentPage {
              id
              name
            }
            ... on SanityContentTopic {
              id
            }
            ... on SanityPage {
              id
              name
            }
            ... on SanityStaticPage {
              id
              name
            }
          }
        }
        image {
          asset {
            url
          }
        }
        description {
          _key
          _type
          _rawBlockContent
          blockContent {
            _key
            _type
            style
            list
            _rawChildren
            children {
              _key
              _type
              marks
              text
            }
          }
        }
      }
    }
  }
`;
