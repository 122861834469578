import styled from '@emotion/styled';
import { Flex, Heading } from 'rebass';
import React, { useRef, useState } from 'react';
import Text from '~components/Elements/Text';
import SanityRichTextContent from '~components/Elements/SanityRichTextContent';

const Arrow = ({ className, height, width }) => {
  return (
    <svg
      className={className}
      height={height}
      width={width}
      viewBox="0 0 320 512"
    >
      <path
        fill="#1a3092"
        d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"
      />
    </svg>
  );
};

const AccordionSection = styled(Flex)`
  display: flex;
  flex-direction: column;
`;

const AccordionItm = styled(Flex)`
  border: none;
  background-color: #fff;
  color: #444;
  cursor: pointer;
  padding: 18px 8px 18px 0;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #33333320;
  outline: none;
  transition: background-color 0.6s ease;

  :hover,
  .active {
    background-color: #fff;
  }

  .accordion__icon {
    margin-left: auto;
    transition: transform 0.6s ease;
  }

  .rotate {
    transform: rotate(90deg);
  }
`;

const AccordionContent = styled(Flex)`
  background-color: white;
  overflow: auto;
  transition: max-height 0.6s ease;
`;

function AccordionItem({ title, content }) {
  const [setActive, setActiveState] = useState('');
  const [setHeight, setHeightState] = useState('0px');
  const [setRotate, setRotateState] = useState('accordion__icon');

  const contentRef = useRef(null);

  function toggleAccordion() {
    setActiveState(setActive === '' ? 'active' : '');
    setHeightState(
      setActive === 'active' ? '0px' : `${contentRef.current.scrollHeight}px`
    );
    setRotateState(
      setActive === 'active' ? 'accordion__icon' : 'accordion__icon rotate'
    );
  }

  return (
    <AccordionSection>
      <AccordionItm
        className={`accordion ${setActive}`}
        onClick={() => toggleAccordion()}
      >
        <Text font={'HeadingS'} color={'blueBlue160'}>
          {title}
        </Text>
        <Arrow className={`${setRotate}`} width={10} />
      </AccordionItm>
      <AccordionContent ref={contentRef} style={{ maxHeight: `${setHeight}` }}>
        <SanityRichTextContent content={content} />
      </AccordionContent>
    </AccordionSection>
  );
}

function Accordion({ data }) {
  const headingSize = data.headingSize || 'h3';
  const AccordionFlex = styled(Flex)`
    width: 100%;
    display: grid;
  `;

  if (!data.items) {
    return null;
  }

  return (
    <AccordionFlex
      flexDirection="column"
      alignItems="flex-start"
      alignSelf="center"
      width="100%"
      maxWidth={['100%', '100%', '816px', '816px']}
      mb="40px"
      mt="40px"
    >
      {data.title ? (
        <Heading
          as={headingSize}
          fontSize={headingSize === 'h2' ? '28px' : '20px'}
          fontWeight="bold"
          color={headingSize === 'h2' ? '#1a3092' : '#000'}
          mt={0}
          mb={headingSize === 'h2' ? '28px' : '28px'}
          width="100%"
        >
          {data.title}
        </Heading>
      ) : null}
      <Flex
        flexDirection="column"
        width="100%"
        style={{ borderTop: '1px solid #33333320' }}
      >
        {data.items.map(itm => (
          <AccordionItem
            key={itm.title}
            title={itm.title}
            content={itm.content}
          />
        ))}
      </Flex>
    </AccordionFlex>
  );
}

export default Accordion;
